import {Supplier} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  DashboardColumnSortDirection,
  DashboardColumnsSchema
} from 'App/components/dashboards/commons/dashboardColumnSchema';
import {Link} from 'react-router';
import ActionsCell from './ActionsCell';

export const columnSchema: DashboardColumnsSchema<Supplier> = [
  {
    header: 'Name',
    content: (data) => <Link to={`/suppliers/${data.id}`}>{data.name}</Link>,
    sort: {
      key: 'name',
      direction: DashboardColumnSortDirection.ASC
    }
  },
  {
    header: 'ID',
    content: (data) => data.external_id || '--',
    sort: {
      key: 'external_id',
      direction: DashboardColumnSortDirection.ASC
    }
  },
  {
    header: 'Mailing Address',
    content: (data) => data.mailing_address?.formatted_address || '--',
    sort: {
      key: 'mailing_address.formatted_address',
      direction: DashboardColumnSortDirection.ASC
    }
  },
  {
    header: 'Billing Address',
    content: (data) => data.billing_address?.formatted_address || '--',
    sort: {
      key: 'billing_address.formatted_address',
      direction: DashboardColumnSortDirection.ASC
    }
  },
  {
    header: 'Email',
    content: (data) => data.billing_email || '--',
    sort: {
      key: 'billing_email',
      direction: DashboardColumnSortDirection.ASC
    }
  },
  {
    header: 'Contact(s)',
    content: (data) => data.users?.map((user) => user.full_name).join(', ') || '--'
  },
  {
    header: 'Actions',
    content: (data) => <ActionsCell supplierId={data.id} />
  }
];
